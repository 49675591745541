import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import InlineCode from '@primer/gatsby-theme-doctocat/src/components/inline-code';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The Primer `}<inlineCode parentName="p">{`SelectPanel`}</inlineCode>{` component in Primer ViewComponents should be used in place of the `}<inlineCode parentName="p">{`SelectMenu`}</inlineCode>{` component from Primer CSS. It provides a number of accessibility improvements over the CSS component, supports multiple data fetching strategies, customizable filtering behavior, and more.`}</p>
    <h3>{`The show button`}</h3>
    <p><inlineCode parentName="p">{`SelectMenu`}</inlineCode>{`s are powered by two native elements. The `}<inlineCode parentName="p">{`<details>`}</inlineCode>{` element contains the list of menu items while the `}<inlineCode parentName="p">{`<summary>`}</inlineCode>{` element typically contains a button that opens the menu.`}</p>
    <p><inlineCode parentName="p">{`SelectPanel`}</inlineCode>{`s work in a similar fashion. Rather than a `}<inlineCode parentName="p">{`<summary>`}</inlineCode>{` element however, `}<inlineCode parentName="p">{`SelectPanel`}</inlineCode>{`s use the `}<inlineCode parentName="p">{`show_button`}</inlineCode>{` slot, which renders a Primer button.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Alpha::SelectPanel.new) do |panel| %>
  <% panel.with_show_button { "Click me" } %>
<% end %>
`}</code></pre>
    <p>{`Panels can also be opened in JavaScript by calling the `}<inlineCode parentName="p">{`show()`}</inlineCode>{` method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`document.querySelector('select-panel').show();
`}</code></pre>
    <h3>{`Panels are dialogs`}</h3>
    <p><inlineCode parentName="p">{`SelectMenu`}</inlineCode>{`s wrap their `}<inlineCode parentName="p">{`<summary>`}</inlineCode>{`/`}<inlineCode parentName="p">{`<details>`}</inlineCode>{` elements inside the `}<a parentName="p" {...{
        "href": "https://github.com/github/details-dialog-element"
      }}><inlineCode parentName="a">{`<details-dialog>`}</inlineCode></a>{` element to display their items in a modal dialog. `}<inlineCode parentName="p">{`SelectPanel`}</inlineCode>{`s have this functionality built in, i.e. there is no need to wrap them in any additional elements.`}</p>
    <p><inlineCode parentName="p">{`SelectPanel`}</inlineCode>{`s are anchored to their show buttons. In other words, they appear attached to their show buttons and act  like menus or popovers. However, `}<inlineCode parentName="p">{`SelectPanel`}</inlineCode>{`s use the native `}<inlineCode parentName="p">{`<dialog>`}</inlineCode>{` element under the hood and therefore exhibit `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/"
      }}>{`dialog behavior`}</a>{` -- they trap focus, prevent scrolling content under the dialog, etc. `}<inlineCode parentName="p">{`SelectMenu`}</inlineCode>{`s by contrast are not backed by dialogs; they do not trap focus and do not prevent page scrolling.`}</p>
    <h3>{`Choosing a fetch strategy`}</h3>
    <p>{`It is common for usages of `}<inlineCode parentName="p">{`SelectMenu`}</inlineCode>{` to fetch list items from a remote server using the `}<inlineCode parentName="p">{`<include-fragment>`}</inlineCode>{` or `}<inlineCode parentName="p">{`<remote-input>`}</inlineCode>{` elements. `}<inlineCode parentName="p">{`SelectPanel`}</inlineCode>{`s also use these elements, but do so automatically "under the hood" depending on the configured fetch strategy.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Element`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Fetch strategy`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<remote-input>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:remote`}</inlineCode>{` (default)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Items are fetched from the server whenever the user types into the filter input text field.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span style={{
              "whiteSpace": "nowrap"
            }}><inlineCode parentName="td">{`<include-fragment>`}</inlineCode></span></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:eventually_local`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Items are fetched once when the panel opens for the first time.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span style={{
              "whiteSpace": "nowrap"
            }}>{`None, i.e. a static list`}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:local`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No remote requests are made.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`For the `}<inlineCode parentName="p">{`:remote`}</inlineCode>{` and `}<inlineCode parentName="p">{`:eventually_local`}</inlineCode>{` fetch strategies, items are fetched using the URL provided in the `}<inlineCode parentName="p">{`src:`}</inlineCode>{` argument.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Alpha::SelectPanel.new(
  fetch_strategy: :remote, # this is the default, shown here for demo purposes
  src: probably_some_rails_path_helper
)) %>
`}</code></pre>
    <h3>{`Rendering list items`}</h3>
    <p>{`When rendering a static list (i.e. using the `}<inlineCode parentName="p">{`:local`}</inlineCode>{` fetch strategy), use the `}<inlineCode parentName="p">{`item`}</inlineCode>{` slot to define list items. `}<inlineCode parentName="p">{`label:`}</inlineCode>{` is the only required argument.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Alpha::SelectPanel.new) do |panel| %>
  <% panel.with_item(label: "My item") %>
<% end %>
`}</code></pre>
    <p>{`When rendering a dynamic list where items are fetched from a remote server, the `}<inlineCode parentName="p">{`SelectPanel`}</inlineCode>{` component expects remote responses to render instances of `}<inlineCode parentName="p">{`Primer::Alpha::SelectPanel::ItemList`}</inlineCode>{`. The `}<inlineCode parentName="p">{`ItemList`}</inlineCode>{` component is the same component that static `}<inlineCode parentName="p">{`SelectPanel`}</inlineCode>{`s use to render list items.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Alpha::SelectPanel::ItemList.new) do |list| %>
  <% list.with_item(label: "My item") %>
<% end %>
`}</code></pre>
    <h4>{`HTML fragments`}</h4>
    <p>{`Note that both the `}<inlineCode parentName="p">{`<include-fragment>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<remote-input>`}</inlineCode>{` elements that `}<inlineCode parentName="p">{`SelectPanel`}</inlineCode>{` uses under the hood require that responses have a content type of `}<inlineCode parentName="p">{`text/fragment+html`}</inlineCode>{`. See the `}<inlineCode parentName="p">{`SelectPanel`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "/components/selectpanel/rails/alpha"
      }}>{`docs`}</a>{` for details.`}</p>
    <h3>{`Choosing a select variant`}</h3>
    <p><inlineCode parentName="p">{`SelectPanel`}</inlineCode>{`s automatically manage list item state, including which items are checked (or "active" in `}<inlineCode parentName="p">{`SelectPanel`}</inlineCode>{` and `}<inlineCode parentName="p">{`ActionList`}</inlineCode>{` parlance). Items can be marked as checked by passing `}<inlineCode parentName="p">{`active: true`}</inlineCode>{` to the item's constructor.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Alpha::SelectPanel.new) do |panel| %>
  <% panel.with_item(label: "My item", active: true) %>
<% end %>
`}</code></pre>
    <p>{`In addition to supporting single-select (the default), `}<inlineCode parentName="p">{`SelectPanel`}</inlineCode>{`s also support multi-select behavior. Selection behavior can be controlled via the `}<inlineCode parentName="p">{`select_variant:`}</inlineCode>{` argument. Options are `}<inlineCode parentName="p">{`:single`}</inlineCode>{` (the default) and `}<inlineCode parentName="p">{`:multiple`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Alpha::SelectPanel.new(
  select_variant: :multiple
)) %>
`}</code></pre>
    <Note mdxType="Note">
When rendering panels and their corresponding dynamic list items, make sure to pass the same <InlineCode mdxType="InlineCode">{"select_variant:"}</InlineCode> argument to both <InlineCode mdxType="InlineCode">{"SelectPanel.new"}</InlineCode> and <InlineCode mdxType="InlineCode">{"SelectPanel::ItemList.new"}</InlineCode>, or you may experience unexpected behavior.
    </Note>
    <h4>{`Identifying list items`}</h4>
    <p>{`When using the `}<inlineCode parentName="p">{`:remote`}</inlineCode>{` fetch strategy in combination with single- or multi-select modes, list items must be uniquely identifiable via the `}<inlineCode parentName="p">{`value:`}</inlineCode>{` argument so the component can "remember" which list items the user has selected. This is important because the component needs a mechanism by which it can reconcile items selected in the client and items the server reports as selected. Neglecting to provide `}<inlineCode parentName="p">{`value`}</inlineCode>{`s can lead to unexpected selection behavior.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= render(Primer::Alpha::SelectPanel.new(
  select_variant: :multiple,
  fetch_strategy: :remote
)) do |panel| %>
  <% panel.with_item(label: "Apples", content_arguments: { data: { value: "apples" } }) %>
  <% panel.with_item(label: "Bananas", content_arguments: { data: { value: "bananas" } }) %>
<% end %>
`}</code></pre>
    <h3>{`Loading behavior`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`SelectPanel`}</inlineCode>{` component automatically displays `}<inlineCode parentName="p">{`Spinner`}</inlineCode>{`s during initial and subsequent remote fetches and announces to screen reader users when requests have succeeded or failed. There is no need to render custom spinners or make custom announcements as must often be done for `}<inlineCode parentName="p">{`SelectMenu`}</inlineCode>{`s.`}</p>
    <h3>{`Where to go next`}</h3>
    <ol>
      <li parentName="ol">{`The official `}<a parentName="li" {...{
          "href": "/components/selectpanel/rails/alpha"
        }}>{`SelectPanel docs`}</a></li>
      <li parentName="ol">{`Have questions? Hop into the #primer-rails Slack channel.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      